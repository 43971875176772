<template>
  <v-container class="pt-10">
    <Basket />
  </v-container>
</template>

<script>
import Basket from "../components/basket";

export default {
  name: "BasketMobile",

  components: {
    Basket
  }
};
</script>
